import { TYPE_CONTENU } from "../utils/enums";
import { Contenu } from "@/types/contenu";
import { TypeEntite } from "@/types/enum";

export const PUB_INDEXES = {
    CARTES_MOBILE: [20, 16, 12, 8, 4],
    CARTES_DESKTOP: [17, 9, 1],
    CATEGORIES: [20, 18, 16, 14, 12, 10, 8, 6, 4, 2],
    EMISSIONS: [18, 14, 10, 6, 2],
    COLLECTION_3_COLS: [4],
};

// Minimum amount of items we need to show the PUB at the end of each block of card list
export const PUB_END_BLOCK = 4;

type IndexesKeys = keyof typeof PUB_INDEXES;
type IndexesValues = (typeof PUB_INDEXES)[IndexesKeys];

export const INFOLETTRE_INDEXES = {
    EMISSIONS: [8],
};

// Recettes and Articles IDs to render Tuile Native pub
export const TUILE_VIDEO_IDS = {
    RECETTES: [5594, 6188, 6192, 6582, 6583],
    ARTICLES: [251],
};

const getPubObj = (index: number) => ({
    id: `pub-${index}`,
    titre: "publicité",
    type: TYPE_CONTENU.PUBLICITE as TypeEntite.Source,
});

export const withPub = (list: Contenu[], indexes: IndexesValues) => {
    const newList = [...list];
    indexes.forEach((index) => {
        if (newList.length > index) {
            newList.splice(index, 0, getPubObj(index));
        }
    });

    return newList;
};

import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { clearDatalayer, IInputContentData, sendCtaEvent,  } from "@telequebec/tq-googleanalytics";
import { objectToGetParams } from "../../utils/object";
import { ShareContent } from "@/types/components/boutonPartage";
import { CTA_LABEL_TEXT } from "./boutonsPartage.constant";

import Bouton from "../bouton";
import BoutonPartageSocial from "./boutonPartageSocial";

import styles from "./boutonsPartage.module.scss";

type PropTypes = {
    className: string;
    itemsToShow: string[];
    media: string;
    text?: string;
    title?: string;
    url?: string;
    contentDataLayer: IInputContentData;
};

const BoutonsPartage = ({
    className,
    itemsToShow = ["partager", "envoyer", "imprimer"],
    media,
    text = "",
    title = "",
    url,
    contentDataLayer,
}: PropTypes) => {
    const shareTitle = title;

    const [shareUrl, setShareUrl] = useState("");
    const shareText = text;

    useEffect(() => {
        let pageUrl = window.location.href;
        const canonicalElement = document.querySelector("link[rel=canonical]");
        if (canonicalElement) {
            pageUrl = canonicalElement.getAttribute("href") || "";
        }
        setShareUrl(url || pageUrl);
    }, [url]);

    const setCTADataLayer = (ctaText: string) => {
        sendCtaEvent({
            interaction: {
                click_url: shareUrl,
                click_text: ctaText,
            },
        });
        clearDatalayer(["interaction"]);
    };

    const handleEnvoyer = () => {
        setCTADataLayer(CTA_LABEL_TEXT.ENVOYER);
        const params = objectToGetParams({ subject: shareTitle, body: shareUrl });
        window.location.href = `mailto:${params}`;
        return false;
    };

    const handleImprimer = () => {
        setCTADataLayer(CTA_LABEL_TEXT.IMPRIMER);
        window.print();
        return false;
    };

    return (
        <ul
            data-component="BoutonPartage"
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            {itemsToShow.includes("partager") && (
                <li className={styles.shareItem}>
                    <BoutonPartageSocial
                        media={media}
                        text={shareText}
                        title={shareTitle}
                        url={shareUrl}
                        contentDataLayer={contentDataLayer}
                        classNameWrapper="shareItemBouton"
                    />
                </li>
            )}
            {itemsToShow.includes("envoyer") && (
                <li className={styles.shareItem}>
                    <Bouton withStyles={false} onClick={handleEnvoyer} icon="courriel_cercle">
                        {CTA_LABEL_TEXT.ENVOYER}
                    </Bouton>
                </li>
            )}
            {itemsToShow.includes("imprimer") && (
                <li className={styles.shareItem}>
                    <Bouton withStyles={false} onClick={handleImprimer} icon="impresion_cercle">
                        {CTA_LABEL_TEXT.IMPRIMER}
                    </Bouton>
                </li>
            )}
        </ul>
    );
};

export default BoutonsPartage;

import React, { useContext } from "react";
import classNames from "classnames";

import Link from "next/link";
import { useRouter } from "next/router";

import { clearDatalayer, sendBreadcrumbEvent } from "@telequebec/tq-googleanalytics";
import { path, pathRoutes } from "../../utils/path";

import Icon from "../icon";
import { findBestCategoriesByParentOrder } from "../../utils/categories";

import SanitizeHTML from "../sanitizeHTML";

import styles from "./breadcrumb.module.scss";
import { Categorie, CategorieCompact } from "@/types/categorie";
import { CategoryContext } from "@/hooks/useCategories";
import { CATEGORIES } from "@/configs/categories";

type PropTypes = {
    className?: string;
    title?: string;
    categories?: CategorieCompact[];
    parentItem?: { title: string; url: string }[];
};

const Breadcrumb = ({ categories = [], className = "", parentItem, title }: PropTypes) => {
    const router = useRouter();
    const { pathname: matchPath } = router;
    const allCategories = useContext(CategoryContext);

    const crumbs = pathRoutes
        // Get all routes that contain the current one.
        .filter(({ path: routePath }) => {
            if (!routePath) {
                return false;
            }

            return routePath.includes(matchPath);
        })
        // Remove duplicated items with the same parent path
        .reduce((accumulator: any[], current) => {
            if (
                !accumulator.find(
                    (item: { parent: { path: string } }) =>
                        item?.parent?.path === current.parent?.path,
                )
            ) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

    const bestCategories = findBestCategoriesByParentOrder(categories, allCategories);

    const getCategoryUrl = (category: Categorie) => {
        const { id, type: typeCategory } = category;

        const thePath =
            typeCategory === "recette" || typeCategory === "categorie"
                ? path.CATEGORY
                : path.PUBLICATIONS;

        switch (id) {
            case CATEGORIES.RECETTES:
                return path.RECETTES;
            case CATEGORIES.PLANIFIER:
                return path.PLANIFIER;
            case CATEGORIES.APPRENDRE:
                return path.APPRENDRE;
            default:
                return path.fill(thePath, category);
        }
    };

    const sendDataLayer = (url: string, text: string) => {
        sendBreadcrumbEvent({
            interaction: {
                click_url: `${process.env.NEXT_PUBLIC_SITE_BASE_URL}${url}`,
                click_text: text,
            },
        });

        clearDatalayer(["interaction"]);
    };

    return (
        <ul
            className={classNames(styles.me, {
                [className]: className,
            })}
            data-component="Breadcrumb"
            data-testid="Breadcrumb"
        >
            <li data-testid="Breadcrumb-homeItem">
                <Link href={path.ACCUEIL} onClick={() => sendDataLayer(path.ACCUEIL, "Accueil")}>
                    <span className={styles.homeMobile}>
                        <Icon icon="accueil_chemine" />
                    </span>
                    <span className={styles.homeDesktop}>Accueil</span>
                </Link>
            </li>
            {crumbs?.map(({ parent }, key) => {
                if (!parent) {
                    return null;
                }

                const { path: crumbPath, name: crumbName } = parent;

                if (crumbName === title) {
                    return null;
                }

                return (
                    <li key={key}>
                        <Link href={crumbPath} onClick={() => sendDataLayer(crumbPath, crumbName)}>
                            <SanitizeHTML html={crumbName} tag="span" />
                        </Link>
                    </li>
                );
            })}
            {bestCategories && (
                <>
                    {bestCategories.map((category: Categorie) => {
                        const { id, nom } = category;

                        const url = getCategoryUrl(category);

                        if (nom === title) {
                            return null;
                        }

                        return (
                            <li data-catid={id} className={styles.item} key={id}>
                                <Link href={url} onClick={() => sendDataLayer(url, nom)}>
                                    <SanitizeHTML html={nom} tag="span" />
                                </Link>
                            </li>
                        );
                    })}
                </>
            )}
            {parentItem &&
                parentItem.map((element, key) => {
                    if (!element) {
                        return null;
                    }

                    const { title: elementTitle, url: elementUrl } = element;

                    if (!elementTitle || !elementUrl) {
                        return null;
                    }

                    return (
                        <li data-testid="Breadcrumb-parentItem" key={key}>
                            <Link
                                href={elementUrl}
                                data-testid="Breadcrumb-parentItem-link"
                                onClick={() => sendDataLayer(elementUrl, elementTitle)}
                            >
                                <SanitizeHTML html={elementTitle} tag="span" />
                            </Link>
                        </li>
                    );
                })}
            <li data-testid="Breadcrumb-currentItem" className={styles.currentItem}>
                <SanitizeHTML html={title || ""} tag="span" />
            </li>
        </ul>
    );
};

export default Breadcrumb;

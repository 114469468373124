import React, { useEffect, useState } from "react";
import classNames from "classnames";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

import { clearDatalayer, IInputContentData, sendShareEvent } from "@telequebec/tq-googleanalytics";
import Bouton from "../../bouton";
import BoutonDropdown from "../../boutonDropdown";
import { SHARING_LABEL_TEXT } from "../boutonsPartage.constant";

import styles from "./boutonPartageSocial.module.scss";

type PropTypes = {
    className?: string;
    classNameWrapper: string;
    media: string;
    size?: number;
    text: string;
    title: string;
    url: string;
    contentDataLayer: IInputContentData;
};

const BoutonPartageSocial = ({
    className = "",
    classNameWrapper,
    media,
    size,
    text,
    title,
    url,
    contentDataLayer,
}: PropTypes) => {
    const [isNativeSharing, setIsNativeSharing] = useState(false);

    const shareTitle = title;
    const shareUrl = url;

    const shareText = text || null;
    const shareMedia = media || null;

    const iconSize = size || 32;
    const iconRound = true;
    const iconFillColor = "black";

    const facebookMessengerAppId = process?.env?.NEXT_PUBLIC_FACEBOOK_MESSENGER_APP_ID;

    const handleDataLayer = (socialName: string) => {
        sendShareEvent({
            social: socialName,
            data: contentDataLayer,
        });

        clearDatalayer(["ecommerce", "interaction"]);
    };

    const content = (
        <ul className={styles.itemsList}>
            <li className={styles.itemList}>
                <FacebookShareButton
                    url={shareUrl}
                    onClick={() => handleDataLayer(SHARING_LABEL_TEXT.FACEBOOK)}
                >
                    <span>
                        <FacebookIcon
                            size={iconSize}
                            round={iconRound}
                            iconFillColor={iconFillColor}
                        />
                    </span>
                    <em>{SHARING_LABEL_TEXT.FACEBOOK}</em>
                </FacebookShareButton>
            </li>
            <li className={styles.itemList}>
                <PinterestShareButton
                    url={shareUrl}
                    description={shareTitle}
                    media={shareMedia||""}
                    onClick={() => handleDataLayer(SHARING_LABEL_TEXT.PINTEREST)}
                >
                    <span>
                        <PinterestIcon
                            size={iconSize}
                            round={iconRound}
                            iconFillColor={iconFillColor}
                        />
                    </span>
                    <em>{SHARING_LABEL_TEXT.PINTEREST}</em>
                </PinterestShareButton>
            </li>
            <li className={styles.itemList}>
                <TwitterShareButton
                    url={shareUrl}
                    title={shareTitle}
                    onClick={() => handleDataLayer(SHARING_LABEL_TEXT.TWITTER)}
                >
                    <span>
                        <TwitterIcon
                            size={iconSize}
                            round={iconRound}
                            iconFillColor={iconFillColor}
                        />
                    </span>
                    <em>{SHARING_LABEL_TEXT.TWITTER}</em>
                </TwitterShareButton>
            </li>
            <li className={styles.itemList}>
                <WhatsappShareButton
                    title={shareTitle}
                    onClick={() => handleDataLayer(SHARING_LABEL_TEXT.WHATSAPP)}
                    url=""
                >
                    <span>
                        <WhatsappIcon
                            size={iconSize}
                            round={iconRound}
                            iconFillColor={iconFillColor}
                        />
                    </span>
                    <em>{SHARING_LABEL_TEXT.WHATSAPP}</em>
                </WhatsappShareButton>
            </li>
            <li className={styles.itemList}>
                <FacebookMessengerShareButton
                    url={shareUrl}
                    appId={facebookMessengerAppId||""}
                    onClick={() => handleDataLayer(SHARING_LABEL_TEXT.MESSENGER)}
                >
                    <span>
                        <FacebookMessengerIcon
                            size={iconSize}
                            round={iconRound}
                            iconFillColor={iconFillColor}
                        />
                    </span>
                    <em>{SHARING_LABEL_TEXT.MESSENGER}</em>
                </FacebookMessengerShareButton>
            </li>
            <li className={styles.itemList}>
                <EmailShareButton
                    url={shareUrl}
                    subject={shareTitle}
                    onClick={() => handleDataLayer(SHARING_LABEL_TEXT.COURRIEL)}
                >
                    <span>
                        <EmailIcon
                            size={iconSize}
                            round={iconRound}
                            iconFillColor={iconFillColor}
                        />
                    </span>
                    <em>{SHARING_LABEL_TEXT.COURRIEL}</em>
                </EmailShareButton>
            </li>
        </ul>
    );

    useEffect(() => {
        let isNativeSharingLocal = false;

        // @todo À retirer s'il y a une version maintenue a jour dynamiquement
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
        );
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari && isMobile) {
            isNativeSharingLocal = true;
        }

        if (isMobile) {
            isNativeSharingLocal = true;
        }

        if (isNativeSharingLocal && !navigator.canShare) {
            isNativeSharingLocal = false;
        }

        setIsNativeSharing(isNativeSharingLocal);
    }, []);

    const shareData = {
        title: shareTitle || "",
        text: shareText || "",
        url: shareUrl,
    };

    const handlePartager = async () => {
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error(`Error: ${err}`);
        }
    };

    if (isNativeSharing) {
        return (
            <span
                data-component="BoutonPartageSocial"
                className={classNames(styles.me, {
                    [className]: className,
                })}
            >
                <Bouton withStyles={false} enabled onClick={handlePartager} icon="partage">
                    Partager
                </Bouton>
            </span>
        );
    }

    return (
        <BoutonDropdown
            content={content}
            direction="right"
            icon="partage"
            withStyles={false}
            width="400px"
            className={classNames(styles.me, {
                [className]: className,
            })}
            classNameWrapper={classNameWrapper}
        >
            Partager
        </BoutonDropdown>
    );
};

export default BoutonPartageSocial;

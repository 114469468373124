export const objectToGetParams = (object) => {
    const params = Object.entries(object)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

    return params.length > 0 ? `?${params.join('&')}` : '';
};

export const getObjectPropertyValue = (object, property, defaultValue = null) => {
    if (typeof object === 'undefined' || !object) {
        return defaultValue;
    }

    if (typeof object !== 'object') {
        return defaultValue;
    }

    if (typeof object[property] === 'undefined' || !object[property]) {
        return defaultValue;
    }

    return object[property];
};

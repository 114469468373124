import { useEffect } from "react";
import { clearDatalayer, sendContentViewEvent } from "@telequebec/tq-googleanalytics";
import { EcommercePropTypes, useEcommerce } from "./useEcommerce";

export const useItemView = (content: EcommercePropTypes) => {
    const { contentItems } = useEcommerce(content);

    useEffect(() => {
        if (contentItems) {
            sendContentViewEvent(contentItems);
        }

        return clearDatalayer();
    }, [contentItems]);
};

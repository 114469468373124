import { Emission, Episode } from "@/types/emissions";
import { Publication } from "@/types/publication";
import { Recette } from "@/types/recette";
import { useMemo } from "react";

export type EcommercePropTypes = Recette | Publication | Emission | Episode;

export const useEcommerce = (content: EcommercePropTypes) => {
    const contentItems = useMemo(() => {
        if (!content) {
            return null;
        }

        const categories = (content as Recette | Publication)?.categories?.map((c) => c.nom) || [];

        return {
            item_variant: content?.type,
            item_id: content?.id.toString(),
            item_name: content?.titre,
            categories: categories,
        };
    }, [content]);

    return { contentItems };
};

export const getTitleDescription = (title, description) => {
    // Check Title
    let pageTitle = title ? String(title).trim() : '';
    if (!pageTitle || pageTitle === '') {
        pageTitle = null;
    }

    // Check Description
    let pageDescription = description ? String(description).trim() : '';
    if (!pageDescription || pageDescription === '') {
        pageDescription = null;
    }

    return {
        title: pageTitle,
        description: pageDescription,
    };
};

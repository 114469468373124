export const CTA_LABEL_TEXT = {
    ENVOYER: 'Envoyer',
    FAVORIS: 'Favoris',
    IMPRIMER: 'Imprimer',
};

export const SHARING_LABEL_TEXT = {
    COURRIEL: 'Courriel',
    FACEBOOK: 'Facebook',
    MESSENGER: 'Messenger',
    PINTEREST: 'Pinterest',
    TWITTER: 'Twitter',
    WHATSAPP: 'WhatsApp',
};

import React, { useState, useCallback, useRef } from "react";
import classNames from "classnames";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import styles from "./boutonDropdown.module.scss";
import Bouton, { BouttonProps } from "../bouton";

export type BoutonDropdownProps = {
    classNameDropdown?: string;
    classNameWrapper?: string;
    content: React.ReactNode;
    direction?: "left" | "right";
    onCancel?: () => void;
    onClick?: () => void;
    width?: string;
} & BouttonProps;

const BoutonDropdown = React.forwardRef<HTMLButtonElement, BoutonDropdownProps>((props, ref) => {
    const {
        classNameDropdown = null,
        classNameWrapper = null,
        content,
        onCancel = () => {},
        onClick = () => {},
        width = null,
        direction = null,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleCancel = useCallback(() => {
        setIsOpen(false);

        // Send onClick to parent, if exist
        if (typeof onCancel === "function") {
            onCancel();
        }
    }, [onCancel]);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useOnClickOutside([dropdownRef, buttonRef], handleCancel);

    // Styles for dropdown
    const dropdownStyle = {
        width: "",
    };
    if (typeof width === "string" && width !== "") {
        dropdownStyle["width"] = width;
    }

    return (
        <span
            data-component="BoutonDropdown"
            className={classNames(styles.me, {
                [`${classNameWrapper}`]: classNameWrapper,
            })}
            ref={ref}
        >
            <Bouton
                {...props}
                ref={buttonRef}
                onClick={(event) => {
                    event.preventDefault();

                    // Remove the focus to the button
                    // event.target.blur();
                    if (
                        document.activeElement &&
                        document.activeElement instanceof HTMLButtonElement
                    ) {
                        document.activeElement.blur();
                    }

                    setIsOpen(!isOpen);

                    // Send onClick to parent, if exist
                    if (typeof onClick === "function") {
                        onClick();
                    }

                    return false;
                }}
            />

            {isOpen && (
                <div
                    ref={dropdownRef}
                    className={classNames(styles.dropdown, {
                        [`${classNameDropdown}`]: classNameDropdown,
                        [styles[`${direction}`]]: typeof styles[`${direction}`] !== "undefined",
                    })}
                    style={dropdownStyle}
                >
                    <div className={styles.dropdownContent}>{content}</div>
                </div>
            )}
        </span>
    );
});

BoutonDropdown.displayName = "BoutonDropdown";

export default BoutonDropdown;
